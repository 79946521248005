import { db, getTimestamp } from "initFirebase";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import randomString from "../../../lib/utils/utilFunctions";
import PaymentCardA from "../PaymentCard/PaymentCardA";
import PaymentCardB from "../PaymentCard/PaymentCardB";
import "./styles.scss";
import { useAuth } from "@hooks/useAuth";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useLocation } from "react-router-dom";
import { TGeneration, isGenOnline } from "interfaces/Generations";
import { getActiveOnlineGenerations } from "@services/GenerationService";
import CurrencySelector from "@components/Navbar/CurrencySelector/CurrencySelector";
import { useCurrencyContext } from "contexts/CurrencyContext";
const PaymentForm = () => {
	const user = useAuth().user;
	let userId;
	if (user !== null) {
		userId = getAuth().currentUser!.uid;
	}
	const [firstPay, setFirstPay] = useState(false);
	const useQuery = () => new URLSearchParams(useLocation().search);
	const urlParams = useQuery();
	let inscriptionType = urlParams.get("modalidad");
	const [inPersonGen, setInpersonGen] = useState<any>(null);
	const [onlineGenerations, setOnlineGenerations] = useState<Array<TGeneration>>([]);
	const actualDate = new Date();
	const { userCountry } = useCurrencyContext();
	const initialStateValues = {
		name: user !== null ? user.name : "",
		email: user !== null ? user.email : "",
		phone: user !== null ? user.phone : "",
		course: "",
		status: "pending",
		personalRefCode: randomString(6),
		refCodeUsed: "",
		created: getTimestamp(),
	};
	const location = useLocation();

	const isFirstPay = async () => {
		const q = await query(
			collection(db, "paymentHistory"),
			where("verified", "==", true),
			where("userId", "==", userId)
		);
		const querySnapshot = await getDocs(q);
		if (querySnapshot.size === 0) {
			setFirstPay(true);
		}
	};

	const getOnlineGenerations = async () => {
		try {
			const response = await getActiveOnlineGenerations();
			setOnlineGenerations(response);
		} catch (error) {
			console.error(error);
		}
	};

	const getGenInPerson = async () => {
		const q = query(
			collection(db, "generations"),
			where("type", "==", "in-person"),
			where("active", "==", true)
		);

		onSnapshot(q, async (querySnapshot) => {
			if (querySnapshot.size === 0) {
				history.push("/inscripcion");
			} else if (querySnapshot.size > 0) {
				const data: any = { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id };
				const dateArray = data.promoDate.split("/");
				const tempDate = new Date();
				const month = dateArray[1] - 1;
				tempDate.setFullYear(dateArray[2]);
				tempDate.setMonth(month);
				tempDate.setDate(dateArray[0]);
				tempDate.setHours(0, 0, 0);

				setInpersonGen({
					id: data.id,
					...data,
					promoDateD: tempDate,
					promoDate: `${dateArray[0]}/${dateArray[1] - 1}/${dateArray[2]}`,
				});
			}
		});
	};

	const [withUser, setWithUser] = useState(0);
	const [validated, setValidated] = useState(false);
	const [values, setValues] = useState(initialStateValues);
	const [show, setShow] = useState(false);
	const [selectedCourse, setSelectedCourse] = useState<String>(""); // Setea el nombre del "courseInfo#" seleccionado
	const [selectedCourseId, setSelectedCourseId] = useState(0); // Setea la PaymentCard seleccionada para cambiar su estilo
	const [selectedOnlineGeneration, setSelectedOnlineGeneration] = useState<TGeneration>();
	const [courseInfo1, setCourseInfo1] = useState<any>({});
	const [courseInfo2, setCourseInfo2] = useState<any>({});
	const [courseInfo3, setCourseInfo3] = useState<any>({});
	const [paymentMethod, setPaymentMethod] = useState<any>("");
	const [referenceCoupon, setReferenceCoupon] = useState<string>("");
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const getPayments = async () => {
		const getPaymentsDocs = await getDocs(collection(db, "users", userId, "payments"));
		var array: any = [];
		var amount = 0;
		getPaymentsDocs.forEach((doc) => {
			// doc.data() is never undefined for query doc snapshots
			if (doc.data().verified === true) {
				array.push(doc.data());
				amount = amount + parseInt(doc.data().amount);
			}
		});
		if (array.length > 0) {
			history.push("/micuenta/historial-de-pagos");
		}
	};

	useEffect(() => {
		console.log("re render ", inscriptionType);
		if (user !== null) {
			getPayments();
			isFirstPay();
		}
		if (!inscriptionType || inscriptionType === "presencial") {
			getGenInPerson();
		} else if (inscriptionType && isGenOnline(inscriptionType)) {
			getOnlineGenerations();
		}
	}, [user, inscriptionType]);

	useEffect(() => {
		// fix ::: Verifica si 'modalidad' está presente y tiene un valor permitido
		if (!inscriptionType || (inscriptionType !== "online" && inscriptionType !== "presencial")) {
			//fix ::  Si no está presente o es incorrecto, redirige o establece el valor por defecto
			history.replace({
				pathname: location.pathname,
				search: "?modalidad=online",
			});
		}
	}, [inscriptionType, history, location.pathname]);

	// Notificacion de nuevo pago?
	const postInscription = async (userObject) => {
		try {
			await db.collection("payments").doc().set(userObject);
			const data = {
				email: userObject.email,
				name: userObject.email,
				phone: userObject.phone,
				message: "",
			};
			// const response = await sendEmailToAdmin(data);
			// const response2 = await sendEmailToClient(data);
		} catch (error) {
			console.error(error);
		}
	};

	const handleSubmit = async (e) => {
		const form = e.currentTarget;
		e.preventDefault();
		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
			alert("Favor de llenar todos los campos");
		} else {
			if (selectedCourseId === 0) {
				alert("Elige un curso");
				setValidated(true);
			} else {
				// openUrl();
				setLoading(true);
				setValidated(false);
				postInscription({ ...values, course: selectedCourse });
				//setShow(true);
				// setValues(initialStateValues);
				if (withUser === 1) {
					history.push("/ingresar");
				} else {
					// De aqui para abajo se maneja el proceso de pago dependiendo del plan y metodo de pago
					if (selectedCourseId == 1) {
						// Plan estandar (10 sesiones)
						if (paymentMethod == "card") {
							// Plan estandar 10 sesiones con tarjeta
							setTimeout(() =>
								history.push({
									pathname: "/checkout",
									search:
										inscriptionType === "presencial"
											? "?modalidad=presencial"
											: `?modalidad=${selectedOnlineGeneration?.type}` ?? "",
									state: {
										client: values,
										course: {
											...courseInfo1,
											classes: 10,
											genModality: inscriptionType === "presencial" ? "in-person" : "online",
										},
									},
								})
							);
						} else if (paymentMethod == "oxxo") {
							// Plan estandar 10 sesiones con oxxo

							var date = new Date(Date.now());
							date.setDate(date.getDate() + 3);
							let tryToPay = await firebase.functions().httpsCallable("oxxoPay");

							tryToPay({
								client: user !== null ? user : values,
								nombre: courseInfo1.nombre,
								precio: courseInfo1.precio,
								expires_at: Math.round(date.getTime() / 1000),
								type: "oxxo_cash",
							}).then(async (res) => {
								if (res.data === null) {
									alert("Ocurrio un error al procesar el pago");
									return;
								}
								if (user !== null) {
									const date = new Date(Date.now());
									const payAt = new Date(Date.now());
									const data = {
										name: user.name,
										amount: courseInfo1.precio / 100,
										date: payAt.valueOf(),
										image: "",
										concept: courseInfo1.nombre,
										verified: false,
										medio: "OXXO Pay",
										orderId: res.data.id,
										createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
										userId: userId,
										email: user.email,
										checked: false,
										modalidad:
											inscriptionType === "presencial"
												? "in-person"
												: selectedOnlineGeneration?.type ?? inscriptionType ?? "online",
									};
									await db.collection("paymentHistory").add(data);
								}
								setTimeout(() =>
									history.push({
										pathname: "/oxxoref",
										state: {
											client: values,
											course: courseInfo1,
											charge: res.data.charges.data[0],
										},
									})
								);
							});
						} else if (paymentMethod == "spei") {
							// Plan estandar 10 sesiones con SPEI
							var date = new Date(Date.now());
							date.setDate(date.getDate() + 3);
							let tryToPay = await firebase.functions().httpsCallable("oxxoPay");
							tryToPay({
								client: user !== null ? user : values,
								nombre: courseInfo1.nombre,
								precio: courseInfo1.precio,
								expires_at: Math.round(date.getTime() / 1000),
								type: "spei",
							}).then(async (res) => {
								if (user !== null) {
									const date = new Date(Date.now());
									const payAt = new Date(Date.now());
									const data = {
										name: user.name,
										amount: courseInfo1.precio / 100,
										date: payAt.valueOf(),
										image: "",
										concept: courseInfo1.nombre,
										verified: false,
										medio: "SPEI",
										orderId: res.data.id,
										createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
										userId: userId,
										email: user.email,
										checked: false,
										modalidad:
											inscriptionType === "presencial" ? "in-person" : inscriptionType ?? "online",
									};
									await db.collection("paymentHistory").add(data);
								}
								setTimeout(() =>
									history.push({
										pathname: "/speiref",
										state: {
											client: values,
											course: courseInfo1,
											charge: res.data.charges.data[0],
										},
									})
								);
							});
						}
					} else if (selectedCourseId === 3) {
						// Pago con paypal, no se requiere pasar informacion ni hacer nada mas
						window.location.href = "https://www.paypal.com/paypalme/academiadelenguas";
					} else {
						// Plan de promocion aniversario, todo el curso liquidado
						if (paymentMethod == "card") {
							// Plan promocion aniversario, con tarjeta
							setTimeout(() =>
								history.push({
									pathname: "/checkout",
									search:
										inscriptionType === "presencial"
											? "?modalidad=presencial"
											: `?modalidad=${selectedOnlineGeneration?.type}` ?? "",
									state: {
										client: values,
										course: {
											...courseInfo2,
											classes: 60,
											genModality: inscriptionType === "presencial" ? "in-person" : "online",
										},
									},
								})
							);
						} else if (paymentMethod == "spei") {
							// Plan promocion aniversario, con SPEI
							var date = new Date(Date.now());
							date.setDate(date.getDate() + 3);
							let tryToPay = await firebase.functions().httpsCallable("oxxoPay");
							tryToPay({
								client: values,
								nombre: courseInfo2.nombre,
								precio: courseInfo2.precio,
								expires_at: Math.round(date.getTime() / 1000),
								type: "spei",
							}).then(async (res) => {
								if (user !== null) {
									const date = new Date(Date.now());
									const payAt = new Date(Date.now());
									const data = {
										name: user.name,
										amount: courseInfo1.precio / 100,
										date: payAt.valueOf(),
										image: "",
										concept: courseInfo1.nombre,
										verified: false,
										medio: "SPEI",
										orderId: res.data.id,
										createdAt: date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear(),
										userId: userId,
										email: user.email,
										checked: false,
										modalidad:
											inscriptionType === "presencial" ? "in-person" : inscriptionType ?? "online",
									};
									await db.collection("paymentHistory").add(data);
								}

								setTimeout(() =>
									history.push({
										pathname: "/speiref",
										state: {
											client: values,
											course: courseInfo2,
											charge: res.data.charges.data[0],
										},
									})
								);
							});
						}
					}
				}
			}
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	};

	useEffect(() => {
		if (inscriptionType === "presencial") {
			setPaymentMethod("card");
		} else {
			setPaymentMethod("");
		}
	}, [selectedCourseId]);

	const handleClose = () => setShow(false);

	// Obtiene informacion de las modalidades de pago (cantidad, numero de sesiones, etc.) dependiendo de la modalidad.
	const getCourseInfo = async () => {
		if (inscriptionType !== "presencial") {
			db.collection("dynamic_copies")
				.doc("o9n56O0heuZGCdLy1Ek7")
				.collection("program1")
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						setCourseInfo1({
							...doc.data(),
							id: doc.id,
						});
					});
				});
			db.collection("dynamic_copies")
				.doc("o9n56O0heuZGCdLy1Ek7")
				.collection("program2")
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						setCourseInfo2({
							...doc.data(),
							id: doc.id,
						});
					});
				});
		} else {
			db.collection("dynamic_copies")
				.doc("o9n56O0heuZGCdLy1Ek7")
				.collection("in-person-program1")
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						setCourseInfo1({
							...doc.data(),
							id: doc.id,
						});
					});
				});
			db.collection("dynamic_copies")
				.doc("o9n56O0heuZGCdLy1Ek7")
				.collection("in-person-program2")
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						setCourseInfo2({
							...doc.data(),
							id: doc.id,
						});
					});
				});
		}
		db.collection("dynamic_copies")
			.doc("o9n56O0heuZGCdLy1Ek7")
			.collection("program3")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setCourseInfo3({
						...doc.data(),
						id: doc.id,
					});
				});
			});
	};

	const getDiscountCourse = async () => {
		db.collection("dynamic_copies")
			.doc("iai4jfVjEm8kVwkHBW4R")
			.collection("program1Coupon")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setCourseInfo1({
						...doc.data(),
						id: doc.id,
					});
				});
			});
		db.collection("dynamic_copies")
			.doc("iai4jfVjEm8kVwkHBW4R")
			.collection("program2Coupon")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setCourseInfo2({
						...doc.data(),
						id: doc.id,
					});
				});
			});
		db.collection("dynamic_copies")
			.doc("iai4jfVjEm8kVwkHBW4R")
			.collection("program3Coupon")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					setCourseInfo3({
						...doc.data(),
						id: doc.id,
					});
				});
			});
	};

	useEffect(() => {}, [selectedCourse]);

	useEffect(() => {
		if (referenceCoupon === "") {
			getCourseInfo();
		} else {
			getDiscountCourse();
		}
	}, [referenceCoupon]);

	const handleCoupon = async () => {
		try {
			await db
				.collection("payments")
				.where("personalRefCode", "==", values.refCodeUsed)
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						alert("Código inválido");
					} else {
						snapshot.forEach((doc) => {
							setReferenceCoupon(doc.data().personalRefCode);
							alert("Codigo usado");
						});
					}
				})
				.catch((e) => {
					console.log(e);
				});
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div>
			<Row className="my-4" style={{ display: "flex", justifyContent: "center" }}>
				<Col md={6}>
					<div className="text-center">
						<h4>
							¿Tienes un código de referencia? <br /> Ingrésalo aquí
						</h4>
						<Form.Control
							type="text"
							value={values.refCodeUsed}
							className="bg-white w-75 mx-auto my-4"
							placeholder="REF123"
							name="refCodeUsed"
							onChange={(e) => handleInputChange(e)}
						/>
						<button
							type="button"
							onClick={() => handleCoupon()}
							className="tertiary canjear-cupon-btn"
						>
							Canjear cupón
						</button>
					</div>
				</Col>
			</Row>

			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Row>
					<div className="cards__container">
						<h2 style={{ fontWeight: "bolder", color: "#e21b33" }}>Paso I. </h2>
						<h4
							className="instruccion"
							style={{
								paddingLeft: "45px",
								paddingRight: "15px",
								marginBottom: "20px",
								fontWeight: "bold",
							}}
						>
							Ingresa el nombre, correo electrónico y número de teléfono (WhatsApp) del estudiante:
						</h4>
						<Row className="input-row">
							<Col md={12} lg={4} xs={12}>
								<Form.Group className="mb-3" controlId="formBasicName">
									<Form.Label>
										<b>Nombre completo</b>
									</Form.Label>
									<Form.Control
										onChange={handleInputChange}
										required
										type="text"
										name="name"
										value={values.name}
										placeholder="Luke Skywalker"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa un nombre.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={12} lg={4} xs={12}>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>
										<b>Correo electrónico</b>
									</Form.Label>
									<Form.Control
										required
										onChange={handleInputChange}
										type="email"
										name="email"
										value={values.email}
										placeholder="example@example.com"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa un correo válido.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={12} lg={4} xs={12}>
								<Form.Group className="mb-3" controlId="formBasicPhone">
									<Form.Label>
										<b>Número de teléfono</b>
									</Form.Label>
									<Form.Control
										required
										onChange={handleInputChange}
										type="text"
										name="phone"
										minLength={10}
										value={values.phone}
										placeholder="55 1234 5678"
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa un número de 10 dígitos.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>
						<h2 style={{ fontWeight: "bolder", color: "#e21b33" }}>Paso II. </h2>
						<div className="step-2-subtitle-container">
							<h4
								className="instrucción"
								style={{
									paddingLeft: "45px",
									paddingRight: "15px",
									marginBottom: "20px",
									fontWeight: "bold",
								}}
							>
								Selecciona tu plan de pago:
							</h4>
							<CurrencySelector />
						</div>
						<Row className="payment-row">
							<Col
								md={12}
								lg={4}
								xs={12}
								onClick={() => {
									setSelectedCourseId(1);
									setSelectedCourse(courseInfo1.nombre);
								}}
								onChange={handleInputChange}
							>
								<PaymentCardA
									selectedCard={selectedCourseId === 1 ? true : false}
									cardInfo={courseInfo1}
									modality={inscriptionType ?? "presencial"}
									isPromo={false}
									{...(inscriptionType === "online" ? { amountOfClasses: 10 } : {})}
								/>
							</Col>
							<Col
								md={12}
								lg={4}
								xs={12}
								onClick={() => {
									setSelectedCourseId(
										inscriptionType === "presencial" &&
											inPersonGen !== null &&
											actualDate > inPersonGen.promoDateD
											? 0
											: 2
									);
									setSelectedCourse(
										inscriptionType === "presencial" &&
											inPersonGen !== null &&
											actualDate > inPersonGen.promoDateD
											? courseInfo2.nombre
											: ""
									);
								}}
								onChange={handleInputChange}
							>
								<PaymentCardA
									selectedCard={selectedCourseId === 2 ? true : false}
									cardInfo={{
										...courseInfo2,
										promoDate:
											inscriptionType === "presencial" && inPersonGen !== null
												? inPersonGen.promoDateD
												: null,
										promoDateString:
											inscriptionType === "presencial" && inPersonGen !== null
												? inPersonGen.promoDate
												: null,
									}}
									modality={inscriptionType ?? "presencial"}
									isPromo
								/>
							</Col>
							<Col
								md={12}
								lg={4}
								xs={12}
								onClick={() => {
									setSelectedCourseId(3);
									setSelectedCourse(courseInfo3.nombre);
								}}
								onChange={handleInputChange}
							>
								<PaymentCardB
									selectedCard={selectedCourseId === 3 ? true : false}
									cardInfo={courseInfo3}
								/>
							</Col>
						</Row>

						{inscriptionType !== "presencial" && (
							<>
								{selectedCourseId !== 0 && (
									<h2 style={{ fontWeight: "bolder", color: "#e21b33" }}>Paso III. </h2>
								)}
								{selectedCourseId !== 0 && selectedCourseId != 3 && (
									<h4
										className="instruccion"
										style={{
											paddingLeft: "45px",
											paddingRight: "15px",
											marginBottom: "20px",
											fontWeight: "bold",
										}}
									>
										Selecciona tu método de pago preferido:
									</h4>
								)}
								{selectedCourseId === 3 && (
									<h4
										className="instruccion"
										style={{
											paddingLeft: "45px",
											paddingRight: "15px",
											marginBottom: "20px",
											fontWeight: "bold",
										}}
									>
										Haz click en el botón PayPal para continuar con tu inscripción:
									</h4>
								)}
								<div className="button__container text-center" style={{ marginBottom: "30px" }}>
									{selectedCourseId === 1 && inscriptionType !== "presencial" && (
										<button
											className={"tertiary mar-r-btn " + (paymentMethod === "oxxo" ? "active" : "")}
											type="button"
											onClick={() => setPaymentMethod("oxxo")}
										>
											OXXO
										</button>
									)}
									{(selectedCourseId === 2 || selectedCourseId === 1) && (
										<button
											className={
												`tertiary mar-r-btn ${
													userCountry === "mx" ? (selectedCourseId === 1 ? "msi-3" : "msi-6") : ""
												} ` + (paymentMethod === "card" ? "active" : "")
											}
											type="button"
											onClick={() => setPaymentMethod("card")}
										>
											Tarjeta
										</button>
									)}
									{(selectedCourseId === 2 || selectedCourseId === 1) &&
										inscriptionType !== "presencial" && (
											<button
												className={
													"tertiary mar-r-btn " + (paymentMethod === "spei" ? "active" : "")
												}
												type="button"
												onClick={() => setPaymentMethod("spei")}
											>
												SPEI
											</button>
										)}
									{selectedCourseId === 3 && (
										<button
											className="tertiary"
											onClick={() => window.open(courseInfo3.url, "_blank")}
										>
											PayPal
										</button>
									)}
								</div>
							</>
						)}
						<div style={{ marginTop: "30px" }}>
							{onlineGenerations.length > 0 && paymentMethod !== "" && (
								<>
									<h2 style={{ fontWeight: "bolder", color: "#e21b33" }}>Paso IV. </h2>
									<h4
										className="instruccion"
										style={{
											paddingLeft: "45px",
											paddingRight: "15px",
											marginBottom: "20px",
											fontWeight: "bold",
										}}
									>
										Selecciona horario:
									</h4>
									<div className="button__container text-center">
										{onlineGenerations.map((e) => {
											return (
												<button
													className={`tertiary mar-r-btn ${
														selectedOnlineGeneration?.id === e.id ? "active" : ""
													}`}
													type="button"
													onClick={() => setSelectedOnlineGeneration(e)}
												>
													{`${e.classDay} ${e.startHour} - ${e.endHour}`}
												</button>
											);
										})}
									</div>
								</>
							)}
						</div>
						{paymentMethod != "" &&
							selectedCourseId !== 0 &&
							(inscriptionType !== "online" ? inPersonGen.limit > 0 : true) &&
							(inscriptionType === "online" ? selectedOnlineGeneration !== undefined : true) && (
								<div className="button__container text-center">
									{!loading && (
										<button className={"tertiary mar-r-btn active"} type="submit">
											Inscribirme
										</button>
									)}
									{loading && (
										<div className="spinner-border text-danger" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									)}
								</div>
							)}
					</div>
				</Row>
			</Form>

			<Modal centered show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Datos enviados</Modal.Title>
				</Modal.Header>
				<Modal.Body>Hemos recibido tus datos. </Modal.Body>
				<Modal.Footer>
					<button className="tertiary" onClick={handleClose}>
						Cerrar
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default PaymentForm;
